.App {
  text-align: center;
}

.NavBar {
  height: 77px;
  padding: 10px 60px !important;
  box-shadow: 0 0 40px rgb(0 0 0 / 10%);
  padding: 28px 15px;
  font-size: 14px;
  font-weight: 650;
  text-transform: uppercase;
  padding: 25px 20px;
  line-height: 1;
  position: relative;
  color: #4a4a4a;
}

.navitem.active {
  background-color: red;
}

div.dropdown-menu.show {
  background-color: #00b605;
  border-radius: 0;
}
a.dropdown-item {
  color: #fff;
  height: 40px;
  padding-top: 10px;
  text-transform: initial;
  font-weight: 500;
}
a.dropdown-item:hover {
  color: #fff;
  background-color: #4a4a4a;
}

.Container {
  margin-top: 60px;
  font-size: 1.2rem;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.Embed-container {
  /* float: left; */
  width: 1360px;
  height: 2300px;
}

.card {
  height: 15em;
  border: 0 !important;
  color: black;
  text-decoration: none;
  box-shadow: 0 0 40px rgb(0 0 0 / 10%);
}

.card:hover {
  transform: scale(1.05) perspective(1px)
}

.card-img-top {
  height: 8em;
  object-fit: cover;
}

.form-check-input:checked {
  background-color:#00b605!important;
  border-color:#00b605!important;
}
